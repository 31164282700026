require('@search/EngineTest.js');

class SmartCrawler {
    constructor(){
        this.mEngines = [];
    }

    clear(){this.mEngines = [];}

    // Category ids or names
    async load(categories){
        if(categories instanceof Array == false) categories = [categories];

        for(const id of categories){
            if(!this.getEngine(id)){
                const engine = new EngineTest();
                if(await engine.load(id)){
                    this.mEngines.push(engine);
                } else {
                    console.error(`"${id}" is not loaded.`);
                }
            }
        }
    }

    // Category ids or names
    unload(categories){
        if(categories instanceof Array == false) categories = [categories];
        
        for(const id of categories){
            const engine = this.getEngine(id);
            if(engine){
                const idx = this.mEngines.indexOf(engine);
                this.mEngines.splice(idx, 1);
            }
        }
    }

    // Category id or name
    getEngine(id){
        for(const engine of this.mEngines){
            if(engine.id == id || engine.category == id){
                return engine;
            }
        }
        return null;
    }

    async search(keyword){
        let datas = [];
        let error = null;

        try{
            const promises = [];
            for(const engine of this.mEngines){
                promises.push(engine.crawling(keyword));
            }

            const results = await Promise.all(promises);
            for(const result of results) datas = datas.concat(result);

            datas.sort((a, b)=>{
                // 내림차순
                return (a.priority ?? 0) < (b.priority ?? 0) ? 1 : -1;
            });
        } catch(e){
            error = e;
        }

        return {datas, error};
    }
}

global.SmartCrawler = SmartCrawler;

try{
    module.exports = SmartCrawler;
} catch(e){}