require('@search/MSelector.js');
const axios = require('axios');

class EngineTest
{
    constructor(){
        this.mCategory = '';
        this.mCategoryId = null;
        this.mAutoCompletes = '';
        this.mResource = '';
        this.mTitle = '';
        this.mSummary = '';
        this.mDetail = '';
        this.mSearchText = '';
        this.mPriority = '';
        this.mStyle = '';
        this.mselector = new MSelector();

        this.mInitAutoCompletes = false;
        this.mInitResource = false;
        this.mSearchKeyword = '';
        this.mSearchAutoCompletes = null;
        this.mSearchResource = null;
    }

    get id() { return this.mCategoryId; }
    get category() { return this.mCategory; }
    set category(val){ this.mCategory = val;}
    get autoCompletes(){ return this.mAutoCompletes; }
    set autoCompletes(val){
        if(this.mAutoCompletes != val){
            this.mAutoCompletes = val; this.mInitAutoCompletes=false; this.mInitResource=false;
        }
    }
    get resource(){ return this.mResource; }
    set resource(val){
        if(this.mResource != val){
            this.mResource = val; this.mInitResource=false;
        }
    }
    get title(){ return this.mTitle; }
    set title(val){ this.mTitle = val; }
    get summary(){ return this.mSummary; }
    set summary(val){ this.mSummary = val; }
    get detail(){ return this.mDetail; }
    set detail(val){ this.mDetail = val; }
    get searchText(){ return this.mSearchText; }
    set searchText(val){ this.mSearchText = val; }
    get priority(){ return this.mPriority; }
    set priority(val){ this.mPriority = val; }
    get style(){ return this.mStyle; }
    set style(val){ this.mStyle = val; }

    reset(){
        this.mCategory = '';
        this.mCategoryId = null;
        this.mAutoCompletes = '';
        this.mResource = '';
        this.mTitle = '';
        this.mSummary = '';
        this.mDetail = '';
        this.mSearchText = '';
        this.mPriority = '';
        this.mStyle = '';

        this.mInitAutoCompletes = false;
        this.mInitResource = false;
        this.mSearchKeyword = '';
        this.mSearchAutoCompletes = null;
        this.mSearchResource = null;
    }

    // load db crawling
    // category : name(string) or id(number)
    // return : boolean
    async load(category=null){
        if((typeof category != 'string' && typeof category != 'number') || category == ''){
            throw new Error('first parameter must be not empty');
        }

        let data = null;
        try{
            const res = await axios.get('/api/engine/'+category);
            data = res.data;
        } catch(e){
            return false;
        }

        const cmd = JSON.parse(data.info);
        this.mCategory = data.name;
        this.mCategoryId = data.id;
        this.mAutoCompletes = cmd.autoCompletes;
        this.mResource = cmd.resource;
        this.mTitle = cmd.title;
        this.mSummary = cmd.summary;
        this.mDetail = cmd.detail;
        this.mSearchText = cmd.searchText;
        this.mPriority = cmd.priority;
        this.mStyle = cmd.style;
        
        this.mInitAutoCompletes = false;
        this.mInitResource = false;
        this.mSearchKeyword = '';

        return true;
    }

    // update to db crawling
    // return : void
    async save(jwt) {
        // save category
        {
            const params = Object.assign({}, this.structure, {jwt});
            let response;

            try{
                response = await axios.post('/api/engine/save', params);
            } catch (e){
                if(e.response.status == 401){
                    throw new Error('Invalid Session');
                } else throw e;
            }
            if(response.data.error) throw new Error(response.data.error);
            this.mCategoryId = response.data.data;
        }

        // update cache
        if((this.mAutoCompletes ?? '') != ''){
            let cache = await this.mselector.command(this.cacheCommand);
            if((cache instanceof Array) == false){
                if(cache){
                    cache = [cache];
                } else {
                    cache = [];
                }
            }
            
            if(cache.length > 0){
                const params = {jwt, cache};
                let response;
                
                try{
                    response = await axios.post(`/api/search/cache/${this.mCategoryId}`, params);
                } catch (e){
                    if(e.response.status == 401){
                        throw new Error('Invalid Session');
                    } else throw e;
                }
                if(response.data.error) throw new Error(response.data.error);
            }
        }
    }

    // remove 
    async remove(jwt){
        if(this.mCategoryId != null){
            let response;

            try{
                response = await axios.delete(`/api/engine/remove/${this.mCategoryId}`, {params:{jwt}});
            } catch (e){
                if(e.response.status == 401){
                    throw new Error('Invalid Session');
                } else throw e;
            }
            if(response.data.error) throw new Error(response.data.error);
        }
    }
    
    // 크롤링(상황에 따라 서버에서 캐시 데이터 가져옴)
    // catch 처리 권장
    async crawling(keyword) {
        if(keyword == null || keyword.trim() == '') return [];
        
        if(typeof this.mAutoCompletes == 'string' && this.mAutoCompletes.trim() != ''){
            if(this.mCategoryId == null) throw new Error('this method must be called after load()');

            const {data} = await axios.get(`/api/search/cache/${this.mCategoryId}/${keyword}`);

            let result = [];
            for(const d of data){
                result.push(JSON.parse(d.data));
            }

            return result;
        } else {
            const data = await this.mselector.command(this.command,{keyword:[keyword]});
            return data;
        }
    }

    // 부분 테스트(모든 프로세스를 클라이언트 단에서 테스트)
    // catch 처리 권장
    async test(keyword, type='result'){
        keyword = keyword.trim().replace(/ /g, '');
        if(keyword != this.mSearchKeyword){
            this.mInitResource = false;
            this.mSearchKeyword = keyword;
        }
        switch(type){
            case 'autoCompletes':
                if(this.mInitAutoCompletes){
                    return this.mSearchAutoCompletes;
                } else {
                    const data = await this.mselector.command(this.makeCmd('autoCompletes'));
                    this.mSearchAutoCompletes = data;
                    this.mInitAutoCompletes = true;
                    return data;
                }
            case 'resource':
                if(this.mAutoCompletes.trim() != ''){
                    if(!this.mInitAutoCompletes){
                        try{
                            await this.test(keyword, 'autoCompletes');
                        } catch(e){
                            throw new Error('[autoCompletes] '+e.message);
                        }
                    }
                    if(this.mSearchAutoCompletes instanceof Array == false){
                        throw new Error('[autoCompletes] must return an string array.');
                    }
                    for(const i of this.mSearchAutoCompletes){
                        if(typeof i != 'string')
                            throw new Error('[autoCompletes] must return an string array. but, some elements are not string value.');
                    }
                }
                if(this.mInitResource){
                    return this.mSearchResource;
                } else {
                    const _keyword = this.mAutoCompletes.trim() != '' ? this.mSearchAutoCompletes : [keyword];
                    const data = keyword=='' ? [] : await this.mselector.command(this.makeCmd('resource'), {keyword:_keyword});
                    this.mSearchResource = data;
                    this.mInitResource = true;
                    return data;
                }
            case 'result':
                if(!this.mInitResource){
                    try{
                        await this.test(keyword, 'resource');
                    } catch(e){
                        throw new Error((e.message.indexOf('[') != 0 ? '[resource] ' : '')+e.message);
                    }
                }

                if(this.mSearchResource instanceof Array == false){
                    throw new Error('[resource] must return an array.');
                }

                // 검색 인덱스 필요하면 mSearchResource 필터링
                if(this.mAutoCompletes.trim() == ''){
                    return await this.mselector.command(this.testCommand, {__resource:this.mSearchResource});
                } else {
                    const searchText = await this.test(keyword, 'searchText');
                    if(searchText instanceof Array == false || searchText.filter(e=>(typeof e!='string')).length > 0){
                        throw new Error('[searchText] must return a string array.');
                    }
                    const filtered = searchText.map((d, i)=>{
                        if(d.replace(/ /g, '').indexOf(keyword) != -1){return this.mSearchResource[i];}
                        else return null;
                    }).filter(e=>e!=null);
                    
                    return await this.mselector.command(this.testCommand, {__resource:filtered});
                }
            default:
                if(!this.mInitResource){
                    try{
                        await this.test(keyword, 'resource');
                    } catch(e){
                        throw new Error((e.message.indexOf('[') != 0 ? '[resource] ' : '')+e.message);
                    }
                }

                if(this.mSearchResource instanceof Array == false){
                    throw new Error('[resource] must return an array.');
                }

                // 검색 인덱스 필요하면 mSearchResource 필터링
                if(type=="searchText" || this.mAutoCompletes.trim() == ''){
                    return await this.mselector.command(this.makeCmd(type), {__resource:this.mSearchResource});
                } else {
                    const searchText = await this.test(keyword, 'searchText');
                    if(searchText instanceof Array == false || searchText.filter(e=>(typeof e!='string')).length > 0){
                        throw new Error('[searchText] must return a string array.');
                    }
                    const filtered = searchText.map((d, i)=>{
                        if(d.replace(/ /g, '').indexOf(keyword) != -1){return this.mSearchResource[i];}
                        else return null;
                    }).filter(e=>e!=null);

                    return await this.mselector.command(this.makeCmd(type), {__resource:filtered});
                }
        }
    }

    // this.test()에서 전체 테스트용 커맨드
    get testCommand(){
        if(this.mResource == ''){
            throw new Error('resource must be set');
        }

        let result = this.makeCmd('title');
        result += this.makeCmd('summary');
        result += this.makeCmd('detail');
        result += this.makeCmd('searchText');
        result += this.makeCmd('priority');
        result += this.makeCmd('result');
        return result;
    }
    

    // 리스트 검색이 있는 커맨드(데이터 캐싱)
    // return : string
    get cacheCommand(){
        if(this.mResource == ''){
            throw new Error('resource must be set');
        }
        if(this.mAutoCompletes == ''){
            throw new Error('autoCompletes must be set');
        }

        let result = this.makeCmd('autoCompletes');
        result += this.makeCmd('resource');
        result += this.makeCmd('title');
        result += this.makeCmd('summary');
        result += this.makeCmd('detail');
        result += this.makeCmd('searchText');
        result += this.makeCmd('priority');
        result += this.makeCmd('cache');
        return result;
    }

    // 리스트 검색이 없는 커맨드
    // return : string
    get command(){
        if(this.mResource == ''){
            throw new Error('resource must be set');
        }

        let result = this.makeCmd('resource');
        result += this.makeCmd('title');
        result += this.makeCmd('summary');
        result += this.makeCmd('detail');
        result += this.makeCmd('searchText');
        result += this.makeCmd('priority');
        result += this.makeCmd('result');
        return result;
    }

    // return : object
    get structure(){
        return {
            id:this.mCategoryId,
            category:this.mCategory,
            autoCompletes:this.mAutoCompletes,
            resource:this.mResource,
            title:this.mTitle,
            summary:this.mSummary,
            detail:this.mDetail,
            searchText:this.mSearchText,
            priority:this.mPriority,
            style:this.mStyle,
        };
    }

    makeCmd(type){
        switch(type){
            case 'autoCompletes':
                if(this.mAutoCompletes != ''){
                    return `${this.mAutoCompletes} => @{keyword} ; \n`;
                }
                break;
            case 'resource':
                if(this.mResource != ''){
                    return `@{keyword} >> ${this.mResource} => @{__resource} ; \n`;
                }
                break;
            case 'title':
                if(this.mTitle != ''){
                    return `@{__resource} > break{@@ == null} >> ${this.mTitle} => @{__title} ; \n`;
                }
                break;
            case 'summary':
                if(this.mSummary != ''){
                    return `@{__resource} > break{@@ == null} >> ${this.mSummary} => @{__summary} ; \n`;
                }
                break;
            case 'detail':
                if(this.mDetail != ''){
                    return `@{__resource} > break{@@ == null} >> ${this.mDetail} => @{__detail} ; \n`;
                }
                break;
            case 'searchText':
                if(this.mSearchText != ''){
                    return `@{__resource} > break{@@ == null} >> ${this.mSearchText} => @{__search_text} ; \n`;
                }
                break;
            case 'priority':
                if(this.mPriority != ''){
                    return `@{__resource} > break{@@ == null} >> ${this.mPriority} => @{__priority} ; \n`;
                }
                break;
            case 'cache':
                return `@{__resource} > break{@@ == null} >> {let result = {
                    keyword:@{keyword}[@?],
                    searchText:@{__search_text}[@?],
                    data: JSON.stringify({
                        title:@{__title}[@?],
                        summary:@{__summary}[@?],
                        detail:@{__detail}[@?],
                        searchText:@{__search_text}[@?],
                        priority:@{__priority}[@?],
                        category:"${this.mCategory}",
                    }),
                }; result}`;
            case 'result':
                return `@{__resource} > break{@@ == null} >> {let result = {
                    title:@{__title}[@?],
                    summary:@{__summary}[@?],
                    detail:@{__detail}[@?],
                    searchText:@{__search_text} ? @{__search_text}[@?] : '',
                    priority:@{__priority}[@?],
                    category:"${this.mCategory}",
                }; result}`;
        }
        return '';
    }
}

global.EngineTest = EngineTest;

try{
    module.exports = EngineTest;
} catch(e){}