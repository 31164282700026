const axios = require('axios');

/*
    class Mselector
        async command(str, variables = {})
        async execute(str, variables = {})
        setCache(bool) : url request 캐싱 여부. 디폴트:true
        variables:any
*/

class MSelector
{
    constructor(){
        this.variables = {};
        this.cache = {};
    }

    setCache(active=true){
        this.cache = active ? {} : null;
    }

    async execute(str, variables = {}) {
        let result = '';
        this.variables = variables;
        while(true){
            const mate = this.mate(str, 0, '{');
            if(mate == null){
                result += str;
                break;
            } else {
				let prod = await this._command(str.substring(mate.start + 1, mate.end)) ?? '';
				if(prod instanceof Array) prod = prod.join(' ');
				prod = String(prod);

                result += str.substring(0, mate.start);
                result += prod;
                str = str.substring(mate.end + 1);
            }
        }
        return result;
    }

    async command(str, variables = {}) {
        str = str.trim();
        this.variables = variables;
        return await this._command(str);
    }

    async _command(str) {
        const origin = str;
        const marks = ['(', '"', "'", '{', '['];
        const operators = ['>', '>>', '>>>', '1>', '=>'];
        const props = ['>', '>>', '>>>', '1>'];

        let isFirst = true;
        let targetStr = '';
        let target = null;
        let operator = null;
        let ended = null;// reason why command ends
        let isBreak = false;
        let cmdEndResult = null;// 세미콜론 시점에서 명령이 끝날 때 반환할 값
        
        // execute commands
        while(str != ''){
            str = str.trim();
            let spaceIdx;
            const mate = this.mate(str, 0, marks);
            spaceIdx = str.search(/\s/g);// 공백 관련 문자 찾음
            spaceIdx = spaceIdx == -1 ? str.length : spaceIdx;
            if(mate && mate.start < spaceIdx){
                const idx = str.substring(mate.end+1).search(/\s/g);
                spaceIdx = idx != -1 ? idx + mate.end + 1 : -1;
                if(spaceIdx == -1) spaceIdx = str.length;
            }

            // parse a command
            const command = str.substring(0, spaceIdx).trim();
            str = str.substring(spaceIdx+1);
            if(command == '') continue;

            if(ended != null && command != ';') throw new Error(`command already ends in the middle by ${ended} : ${origin}`);

            /* execute */

            // operator
            cmdEndResult = null;
            if(command == ';'){
                cmdEndResult = target;
                isFirst = true;
                target = null;
                targetStr = '';
                operator = null;
                ended = null;
                isBreak = false;
            }
            else if(isBreak){}
            else if(operators.indexOf(command) != -1){
                if(operator != null) throw new Error(`two operator can not be execute concurrently. : ${origin}`);
                operator = command;
            }
            // target
            else {
                if(!isFirst && operator == null) throw new Error(`no operator : ${targetStr} ${command}`);

                // selector
                if(command[0] == '(' && command.search(/\)$/g) != -1){
                    if(isFirst){
                        if(target === null) target = document.querySelector('html');
                        if(operator === null) operator = '>';
                    }
                    if(props.indexOf(operator) == -1) throw new Error(`invalid operator : ${operator} ${command}`);
                    target = await this.execMulti(target, operator, (target)=>{
                        if(target?.querySelectorAll){
                            const list = target.querySelectorAll(command.substring(1, command.length-1));
                            target = [];
                            list.forEach((elem)=>{target.push(elem);});
                            if(target.length == 1) return target[0];
                            if(target.length == 0) return null;
                            return target;
                        } else {
                            return null;
                        }
                    });
                    operator = null;
                }
                // string
                else if((command[0] == '"' && command.search(/"$/g) != -1) ||
                    (command[0] == "'" && command.search(/'$/g) != -1)){
                    if(isFirst){
                        if(target === null) target = "";
                        if(operator === null) operator = '>';
                    }
                    if(props.indexOf(operator) == -1) throw new Error(`invalid operator : ${operator} ${command}`);
                    target = await this.execMulti(target, operator, (target, index)=>{
                        target = this.insertVariable(command.substring(1, command.length-1), target, index);
                        return target?.replace(/\\"/g, '"')?.replace(/\\\'/g, '\'');
                    });
                    operator = null;
                }
                // get/set variable at first
                else if(command.search(/^@{/g) != -1 && this.mate(command, 1, '{')?.end == command.length - 1){
                    if(operator == '=>'){// set
                        if(isFirst) throw new Error(`no data to put : => ${command}`);
                        this.variables[command.substring(2, command.length - 1)] = target;
                        ended = 'setting veriable';
                        // target = null;
                        operator = null;
                    } else {// get
                        target = this.variables[command.substring(2, command.length - 1)];
                        operator = null;
                    }
                }
                // 배열 접근 연산자
                else if(command[0] == '[' && command.search(/]$/g) != -1){
                    if(isFirst) throw new Error(`no data to access : ${command}`);
                    target = await this.execMulti(target, operator, (target, index)=>{
                        const cmd = command.substring(1, command.length-1);
                        return target === null ? null : target[this.insertVariable(cmd, target, index)];
                    });
                    operator = null;
                }
                // javascript
                else if(command[0] == '{' && this.mate(command, 0, '{')?.end == command.length - 1){
                    target = await this.execMulti(target, operator, async (target, index)=>{
                        let __command = command.substring(1, command.length - 1)
                            .replace(/@@/g, '__block_data.__target')
                            .replace(/@\?/g, '__block_data.__index');
                        const __block_data = Object.assign({'__target':target, '__index':index}, this.variables);

                        let idx;
                        while((idx = __command.indexOf('@{')) != -1){
                            const mate = this.mate(__command, idx, '{');
                            if(mate == null) throw new Error(`invalid javascript syntax : ${command}`);
                            __command = __command.substring(0, idx) + `__block_data.${__command.substring(mate.start+1, mate.end)}` + __command.substring(mate.end+1);
                        }
                        try{
                            return eval(__command);
                        } catch(e){
                            throw new Error(`${e.message} : ${command}`)
                        }
                    });
                    operator = null;
                }
                // http request
                else if(command.search(/^url:/g) != -1){
                    if(isFirst) operator = '>';
                    if(props.indexOf(operator) == -1) throw new Error(`invalid operator : ${operator} ${command}`);
                    
                    target = await this.execMulti(target, operator, async (target, index)=>{
                        let url = this.insertVariable(command.substring(4), target, index);
                        let response = null;
                        
                        try{
                            if(this.cache && this.cache[url] !== undefined){
                                response = this.cache[url];
                            } else {
                                const {data} = await axios.get('/api/test/request', {
                                    transformResponse:res=>res,
                                    params:{url:encodeURI(url)}
                                });
                                response = data;
                                if(this.cache) this.cache[url] = data;
                            }
                        } catch(e){
                            if(this.cache) this.cache[url] = null;
                            return null;
                        }
                        return response;
                    });
                    operator = null;
                }
                // filter
                else if(command.search(/^filter{/g) != -1 && this.mate(command, 0, '{')?.end == command.length - 1){
                    if(isFirst) throw new Error(`no data to filter : ${operator} ${command}`);
                    if(operator != '>>') throw new Error(`invalid operator. it must be '>>' with array : ${operator} ${command}`);
                    const newTarget = [];
                    await this.execMulti(target, operator, async (target, index)=>{
                        let __command = command.substring(7, command.length - 1)
                            .replace(/@@/g, '__block_data.__target')
                            .replace(/@\?/g, '__block_data.__index');
                        const __block_data = Object.assign({'__target':target, '__index':index}, this.variables);

                        let idx;
                        while((idx = __command.indexOf('@{')) != -1){
                            const mate = this.mate(__command, idx, '{');
                            if(mate == null) throw new Error(`invalid javascript syntax : ${command}`);
                            __command = __command.substring(0, idx) + `__block_data.${__command.substring(mate.start+1, mate.end)}` + __command.substring(mate.end+1);
                        }
                        try{
                            let res;
                            if((res=eval(__command))){
                                newTarget.push(target);
                            }
                        } catch(e){
                            throw new Error(`${e.message} : ${command}`)
                        }
                    });

                    target = newTarget;
                    operator = null;
                }
                // break
                else if(command.search(/^break{/g) != -1 && this.mate(command, 0, '{')?.end == command.length - 1){
                    if(operator != '>') throw new Error(`invalid operator. it must be '>' with array : ${operator} ${command}`);

                    let __command = command.substring(6, command.length - 1)
                        .replace(/@@/g, '__block_data.__target')
                        .replace(/@\?/g, '__block_data.__index');
                    const __block_data = Object.assign({'__target':target, '__index':null}, this.variables);

                    let idx;
                    while((idx = __command.indexOf('@{')) != -1){
                        const mate = this.mate(__command, idx, '{');
                        if(mate == null) throw new Error(`invalid javascript syntax : ${command}`);
                        __command = __command.substring(0, idx) + `__block_data.${__command.substring(mate.start+1, mate.end)}` + __command.substring(mate.end+1);
                    }
                    try{
                        let res=eval(__command);
                        if(res){
                            isBreak = true;
                            target = null;
                        }
                    } catch(e){
                        throw new Error(`${e.message} : ${command}`)
                    }
                    operator = null;
                }
                // 문자열을 DOM으로 가져옴
                else if(command == 'html'){
                    if(props.indexOf(operator) == -1) throw new Error(`invalid operator : ${operator} ${command}`);
                    if(isFirst) throw new Error(`no data to get : ${operator} ${command}`);
                    target = await this.execMulti(target, operator, (target)=>{
                        if(typeof target == 'string'){
                            let parser = new DOMParser();
                            return parser.parseFromString(target, 'text/html');
                        } else {
                            return null;
                        }
                    });
                    operator = null;
                }
                // JSON
                else if(command == 'json'){
                    if(props.indexOf(operator) == -1) throw new Error(`invalid operator : ${operator} ${command}`);
                    if(isFirst) throw new Error(`no data to put : ${operator} ${command}`);
                    target = await this.execMulti(target, operator, (target)=>{
                        try{
                            return JSON.parse(target);
                        } catch(e){
                            return null;
                        }
                    });
                    operator = null;
                }
                // safe string
                else if(command == 'safe'){
                    if(props.indexOf(operator) == -1) throw new Error(`invalid operator : ${operator} ${command}`);
                    if(isFirst) throw new Error(`no data to put : ${operator} ${command}`);
                    target = await this.execMulti(target, operator, (target)=>{
                        try{
                            return target.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
                        } catch(e){
                            return null;
                        }
                    });
                    operator = null;
                }
                // 기타
                else {
                    if(isFirst) operator = '>';
                    if(isFirst) target = window;

                    if(operator == '=>') throw new Error(`invalid variable syntax to set. it must be '=> @{name}' : ${operator} ${command}`);

                    target = await this.execMulti(target, operator, (target, index)=>{
                        let cmd = this.insertVariable(command, target, index);
                        if(cmd.charAt(0) == '@') throw new Error(`invalid variable syntax : ${command}`);
                        return this.props(target, cmd, `${operator ?? ''} ${command}`);
                    });
                    operator = null;
                }
                targetStr = command;
                isFirst = false;
            }
        }
        if(operator != null) throw new Error(`the command ends with operator : ${origin}`);
        return cmdEndResult ? cmdEndResult : target;
    }

    // 단일/다중 실행
    async execMulti(target, operator, func) {
        if(operator=='>') return await func(target, 0);
        if(target instanceof Array == false) return await func(target, 0);

        if(operator=='>>' || operator=='>>>'){
            let list = [];
            try{
                await new Promise((resolve, reject)=>{
                    let count = target.length;
                    if(count == 0) {resolve(); return;}
                    let f = async (target, index)=>{
                        if(count > 0){
                            try{
                                if(operator=='>>>'){
                                    list[index] = await this.execMulti(target, '>>', func);
                                } else {
                                    list[index] = await func(target, index);
                                }
                                count --;
                            } catch(e){
                                count = 0;
                                reject(e);
                            }
                            if(count == 0) resolve();
                        }
                    };
                    for(const i in target){
                        f(target[i], Number(i));
                    }
                });
                if(operator=='>>>'){
                    let newList = [];
                    for(const item of list)
                        newList = newList.concat(item);
                    list = newList;
                }
            }catch(e){ throw e; }
            return list;
        }
        if(operator=='1>'){
            return target.length > 0 ? await func(target[0], 0) : null;
        }
        return null;
    }

    // 속성/함수 실행
    props(target, props, origin) {
        if(target == null) return null;
        try{
            return eval('target.'+props) ?? null;
        } catch(e){
            throw new Error(`[props] ${e.message} : ${origin}`);
        }
    }

    // 문자열에 변수 삽입
    // ex) abc$@
    // ex) abc${var}
    insertVariable(str, target, index){
        let string = str.replace(/@@/g, String(target ?? '')).replace(/@\?/g, String(index));
        let startVar = 0;
        while((startVar = string.indexOf('@{')) != -1){
            let endVar = string.indexOf('}', startVar);
            if(endVar == -1) throw new Error(`invalid string syntax : ${str}`);
            let name = string.substring(startVar+2, endVar);
            string = string.substring(0, startVar) + String(this.variables[name] ?? '') + string.substring(endVar+1);
        }
        return string;
    }

    // mark의 짝을 찾음. ex) {의 짝은 }, [의 짝은 ]
    mate(str, offset = 0, mark = null) {
        // 체크할 문자
        const start = ['(', '{', '['];
        const end = [')', '}', ']'];
        const notStack = ['"', "'","`"];
        const marks = start.concat(end).concat(notStack);
        const starts = start.concat(notStack);

        // 첫 문자 찾음
        let startIdx = str.length;
        for(const c of starts){
            if(mark != null){
                if(typeof mark == "object"){
                    if(mark.indexOf(c) == -1) continue;
                } else {
                    if(mark != c) continue;
                }
            }
            const i = str.indexOf(c, offset);
            if(i != -1 && startIdx > i){
                startIdx = i;
            }
        }
        
        // 문자 못찾음
        if(startIdx == str.length){return null;}

        // 짝 찾기 초기화
        let idx = startIdx + 1;
        const char = str.charAt(startIdx);
        const mate = (start.indexOf(char) != -1 ? end[start.indexOf(char)] : char);
        let stack = [mate];
        let isNotStack = notStack.indexOf(mate) != -1;

        // 짝 찾기
        while(idx < str.length){
            const c = str.charAt(idx);
            
            if(marks.indexOf(c) != -1 && str.charAt(idx - 1) != '\\'){
                if(c == stack[0]){
                    stack.shift();
                    if(stack.length > 0){
                        isNotStack = notStack.indexOf(stack[0]) != -1;
                    }
                } else if(!isNotStack){
                    if(end.indexOf(c) != -1){
                        throw new Error('괄호 매칭 오류');
                    }
                    const i = notStack.indexOf(c);
                    if(i != -1){
                        stack.unshift(c);
                    } else {
                        stack.unshift(end[start.indexOf(c)]);
                    }
                    isNotStack = notStack.indexOf(stack[0]) != -1;
                }

                if(stack.length == 0){
                    return {mark:char, start:startIdx, end:idx};
                }
            }
            idx ++;
        }


        return null;
    }
}

global.MSelector = MSelector;
global.axios = axios;

try{
    module.exports = MSelector;
} catch(e){}